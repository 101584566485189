import React from 'react'
import './portoCard.css'
function PortoCard(props) {
  return (
    <>
        <div className="portocard" style={{cursor:'pointer'}}onClick={()=> window.location.href = props.link}> 
            <div className="portoimg">
                <img src={props.image} alt=""/>
            </div>
            <div className="ttl">
                <h1>{props.title}</h1>
            </div>
            <div className="desc">
                <p>Created at : {props.date}</p>
            </div>
        </div>
    </>
  )
}

export default PortoCard