import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import Navbar from "../navbar/navbar";
import './undefined.css';
function Undefined() {
    const [loadState, setLoadState] = useState(false);
    const [eEgg, setEEgg] = useState(false);
    const loc = useLocation();
    useEffect(()=>{
        console.log(loc)
        setTimeout(()=>{
            setLoadState(true);
        }, 4000)
    },[])
    const [clicks, setClicks] = useState(0);
    const click = (e) =>{
        e.preventDefault();
        // setClicks(clicks + 1);
        if(clicks === 4){
            setEEgg(true);
            setClicks(clicks + 1);

        } else {
            setClicks(clicks + 1);
        }
    };
    return(
        <>
            <Navbar page={`${loc.pathname.split('/')[1].length > 5 ? loc.pathname.split('/')[1].slice(0, 5) + '...' : loc.pathname.split('/')[1]}`} linkstate={3400}></Navbar>
            {loadState && <><div className="error" style={{ color: "red", fontFamily: "monospace", margin: "10px", textOverflow: "ellipsis", overflow:"hidden", whiteSpace:""}}>
                <h3 style={{}}>System could not find the directory : {loc.pathname}.</h3>
                <h3>It might have been deleted, terminated, or is has gone offline (for a few periods, or even forever!). </h3>
                <h2>If you believe the directory is online,</h2>
                <h2>Contact The System Administrator (Operator) at the top navbar above!</h2>
                <h2 onClick={(e) => { click(e) }} style={{ cursor: "pointer", userSelect:"none"}}><span style={{ color: "yellow" }}>-</span> <span style={{ color: "cyan", textDecoration: "underline" }}>stepwebdev.me</span> <span style={{color:"lime"}}>{clicks === 0 ? '' : ` (Click count: ${clicks})`}</span></h2>
            </div>
            
            </>}
           {
            eEgg && <>
                    <Link to='/' style={{ color: "red", position: 'fixed', bottom: "5px", right: "0px", fontStyle: "italic", textDecoration: 'line-through'}}>
                <p style={{ color: "yellow", cursor: "pointer", fontWeight:"bolder",}}><span style={{textDecoration:"line"}}>
                    For GbE, From -, 2024.
                    </span>
                    </p> 
            </Link>
                <p style={{color:"grey", position:"fixed", left:"10px", bottom:"10px", fontSize:"13px", fontFamily:"monospace"}}>code? zMgHjZk9197</p>
                <Link to='/hannah' style={{position:"fixed", color:"grey", fontFamily:"monospace",bottom:"0px", right:"0px"}}>Secret Service</Link>    
            </>
           } 
            
        </>
    )
}
export default Undefined;