import React, { useState } from 'react'
import Navbar from '../navbar/navbar';
import './hannah.css';
import mewingcool from './images/mewing cool.png';
import shh from './images/shh.png';
import maximize from './images/maxsimize.png';
import minimize from './images/minimize.png';
import x from './images/x.png';
const Hannah = () => {
  const [loadState, setLoadState] = useState(false);

  setTimeout(()=>{
    setLoadState(true);
  }, 4750);
  return (
    <>
        <Navbar page="forhannah.exe" linkstate={4400}></Navbar>
        {
          loadState && <> 
        <div className="console_border">
          <div className="trx">
                <div className="cmd_title">
                  <div className="tetel">
                    <p>Adminstrator - Command Prompt | forhannah.exe</p>
                  </div>
                  <div className="icons">
                    {/* <img src={maximize} alt="" /> */}
                    <img src={x} alt="" />
                  </div>
                </div>
                <div className="console">
                    <p>IHACOY HANNAH {'<3333'} AWOKAWOAWK</p>
                    <p>Kamu gemes banget aku suka deh</p>
                    <img src={shh} alt="" />
                    <h2 style={{color:"red", textShadow:"0px 0px 10px red"}}>YANG LIAT DIEM AJA YA!!!</h2>
                </div>
          </div>
        </div>
          
          
          </>
        }
    </>
  )
}

export default Hannah;