import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {

    const savedCallback = useRef();
    // console.log(process.env.REACT_APP_CODE)
    // Remember the latest callback.

    
    useEffect(() => {
        setTimeout(()=>{
            savedCallback.current = callback;
        }, 100)
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      setTimeout(()=>{
          function tick() {
              savedCallback.current();
          }
          if (delay !== null) {
              let id = setInterval(tick, delay);
              return () => clearInterval(id);
          }
      }, 3000);  
    }, [delay]);
}
export default useInterval;