import { Link, Route, Routes } from 'react-router-dom';
import './navbar.css';
import { useEffect, useState } from 'react';
import { useTypewriter, Cursor} from 'react-simple-typewriter';
import useInterval from '../interval/useInterval';
function Navbar(props) {
        const [linkState, setLinkState] = useState(false);
        const [isBlinking, setIsBlinking] = useState(true);
        const [othersClicked, setOthersClicked] = useState(false);
        const [isHannah, setIsHannah] = useState(false);
        useEffect(()=>{
            if(props.page === 'forhannah.exe'){
                setIsHannah(true);
            }
        });
    useInterval(() => {
        if (isBlinking == true) {
            // console.log('blink');
            setIsBlinking(false);
        } else if (isBlinking == false) {
            setIsBlinking(true);
            // console.log('noblink');
        }
    }, 500)
        useEffect(()=>{
            setTimeout(()=>{
                setLinkState(true);          
            }, props.linkstate);
            
        },[]);
        useEffect(()=>{
            setTimeout(()=>{
                setIsBlinking(false);
            },2300);
        }, [])
 
        useEffect(()=>{
            document.title = `${props.page} | stepwebdev.me`;
        },[]);

        const others = () => {
            if (othersClicked) {
                setOthersClicked(false);
            } else {
                setOthersClicked(true);
            }

        }
        // const interval = () => {
        //     setInterval(() => {
          
        //         // console.log('second')
        //     }, 1000);
        // }
        const [text] = useTypewriter({
            // The "","","","","","","","","", is needed, its a delay before typing.
            words: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", `${props.page == "confess.exe" ? 'bash confess.exe' : props.page == "editor.exe" ? "editor.exe" : `cd ${props.page}`}`, ''],
            loop: 1,
            delaySpeed: 100,
            typeSpeed: 100,
            deleteSpeed: 0
        });

    return (
        <>
            <div className="navbar" style={{}}>
                <div className={`rootsh ${linkState ? "" : "preload"} ${isHannah ? "hannah" : ""}`}>
                    <h2><span style={{ color: "rgba(255,65,0,0.85)" }}>root</span><span style={{ color: "cyan" }}>@</span><span style={{ color: "rgba(255,220,0,1)" }}>Stephen</span><span style={{ color: "cyan", }}>(/{linkState && props.page})</span>:<span className='typewritter'>{text}</span><span className={`${isBlinking ? 'blinking' : 'noblinking'}`} style={{ fontSize: "21px" }}>▌</span></h2>
                </div>
                {linkState && (
                    <div className="links" id='links'>
                        <Link to='/' className='link'>Home</Link>
                        <Link to='/projects' className='link'>Projects</Link>
                        <Link to='/contacts' className='link'>Contacts</Link>
                        <Link to='/olderportofolio' className='link oldport'>Old Portfolios</Link>
                        <Link to='/code_editor' className='link special code'>Code Editor</Link>
                        <Link to='/unknown' className='link unknown' style={{}}>???</Link>
                        <p className={`link ${!othersClicked ? 'special' : ''}`} id='others' onClick={()=> others()}>Others</p>
                    </div>
                )}  
            </div>

            {
                linkState && <>
            <div className={`dropdown ${othersClicked ? 'open' : 'close'}`}>
                <h2>Other links:</h2>
                <div className="row">
                    <Link to='/olderportofolio' className='link oldport row'>Old Portfolios</Link>
                    <Link to='/code_editor' className='link special code row'>Code Editor</Link>
                    <Link to='/unknown' className='link' style={{color:'red'}}>???</Link>
                    {/* <Link to='/' className='link'>Reserved Links</Link> */}
                    
                </div>
            </div>

                </>
            }
        </>
    );
}
export default Navbar;