import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Navbar from './components/navbar/navbar';
import Projects from './components/projects/projects';
import Contacts from './components/contacts/contacts';
import img1 from './images/myselfobviously.png'; 
import Undefined from './components/undefined/undefined';
// import MyNewCrush from './components/Divina/divina';
import Code from './components/code/Code';
import Gabby from './components/Gabby/Gabby';
import OldPortofolios from './components/oldportofolios/oldportofolios';
import { useEffect } from 'react';
import Hannah from './components/Hannah/Hannah';


function App() {
  // FOR CONSOLE LOGGING
  setInterval(()=>{
    // console.clear();
    // console.log('Hello! I am Stephen, the creator of this website. I am a self thought programmer, and i am 13 years old. I started to code when i was 11, and i am quite good at making websites. I have alot of friends and i usually play games such as CS:GO, Valorant, Roblox, and Minecraft. I live in Indonesia, I speak English, Indonesian, And a little bit of Russian (Don\'t roast my russian please...). Also you can reach me at the Contacts at the top right of the screen.');
    console.log('%cWhat are you doing here? Hacking the website? Pfft, Pathetic. Get the Fk out of HERE!!!', 'color:red; font-size: 20px; background-color: black; font-family: Sans-Serif; padding: 10px; display:flex; justify-content: center; align-items:center; border-radius:10px; margin-top: 10px; border: 5px solid cyan;' )
  },[500]);
  return (
    <>
    {/* <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/projects' element={<Home />} />
    </Routes> */}
    <div className="App">
      <>
      
      <Routes>
            <Route path='/' element={<Home image={img1} />}></Route>
            <Route path='/projects' element={<Projects/>}></Route>
            <Route path='/contacts' element={<Contacts />}></Route>
            {/* <Route path='/divina' element={<MyNewCrush></MyNewCrush>}></Route>  NO MORE DvNA*/ } 
            <Route path='*' element={<Undefined></Undefined>}></Route>
            <Route path='/code_editor' element={<Code></Code>}></Route>
            {/* <Route path='/prettyelisa' element={<Gabby></Gabby>}></Route> */}
            <Route path='olderportofolio' element={<OldPortofolios></OldPortofolios>}></Route>
            <Route path='/hannah' element={<Hannah></Hannah>}></Route>
      </Routes>
      </>
    </div>
    </>
  );
}

export default App;
