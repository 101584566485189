import React, { useState } from 'react'
import Navbar from '../navbar/navbar'
import './OldPortofolios.css'
import PortoCard from './portoCard/portoCard'
import may2022 from './images/may2022.png'
import december2022 from './images/december2022.png'

const OldPortofolios = () => {

  const [oldPortoState, setOldPortoState] = useState(false);
  setTimeout(()=>{
    setOldPortoState(true);
  }, 3500);

  return (
    <>
    <Navbar page="oldvers" linkstate={3000}/>

    {
     oldPortoState && <>
          <div className="oldportos">
            {/* <h1 style={{color:"white"}}>IN PROGRESS, MO ISTIRAHAT GUE COK</h1> */}
            <div className="mid_content">
              <div className="titl">
                <h1>Some older portofolios of mine: </h1>
              </div>
              <div className="chooser">
                <div className="lft">
                  <PortoCard image={december2022} title='Portofolio 1' date='Dec 15 2022' link='https://myportofolioit.old.stepwebdev.me/'></PortoCard>
                </div>
                <div className="rig">
                  <PortoCard image={may2022} title='Portofolio 2' date='May 5 2022' link='https://stepportofolio.old.stepwebdev.me/'></PortoCard>
                </div>
              </div>
            </div>
          </div>
      </>
    }
        
    </>
  )
}

export default OldPortofolios